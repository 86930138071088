import { IconMap } from '../../../../../shared/sprite';
import { SidebarTabLayout } from '../shared/SidebarTabLayout';
import { SnippetsTab, SortState } from './lib';
import { useMemo, useState } from 'react';
import { useSnippetsPermissions } from '../../../../../features/teams';
import { Tab } from '../shared';
import { SnippetsHeader } from './SnippetsHeader';
import { SnippetType } from '@distribute/shared/types';
import { TeamSnippets } from './TeamSnippets';
import { PersonalSnippets } from './PersonalSnippets';
import { useSelector } from 'react-redux';
import { snippetsModel } from '../../../../../features/snippets';
import { SnippetsList } from './SnippetsList';
import { NoSnippetsFoundScreen } from './NoSnippetsFoundScreen';
import { SidebarSnippetPreview } from './SidebarSnippetPreview';
import { editorLeftSidebarModel } from '../../../model';

export const SnippetsContent = () => {
  const { isCanCreatePersonalSnippets } = useSnippetsPermissions();

  const [selectedTab, setSelectedTab] = useState<string>(SnippetsTab.TEAM);

  const [isSearchInputOpen, setIsSearchInputOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const [sortMethod, setSortMethod] = useState<SortState>(
    SortState.LAST_CREATED
  );
  const [selectedFilter, setSelectedFilter] = useState<SnippetType | undefined>(
    undefined
  );

  const snippets = useSelector(snippetsModel.selectors.selectAvailableSnippets);

  const previewSnippet = useSelector(
    editorLeftSidebarModel.selectors.selectSnippetForPreview
  );

  const readySnippets = useMemo(() => {
    return snippets.filter((s) => !s.isDraft);
  }, [snippets]);

  const foundSnippets = useMemo(() => {
    return readySnippets.filter((snippet) =>
      new RegExp(searchQuery, 'ig').test(snippet.name)
    );
  }, [readySnippets, searchQuery]);

  const tabs: Tab[] = useMemo(
    () => [
      {
        id: SnippetsTab.TEAM,
        name: 'Team',
        icon: IconMap.Building07,
      },
      {
        id: SnippetsTab.PERSONAL,
        name: 'Personal',
        icon: IconMap.CrossedEye,
        disabled: !isCanCreatePersonalSnippets,
        tooltip: 'Personal snippets are not allowed in your team.',
      },
    ],
    [isCanCreatePersonalSnippets]
  );

  const handleSearchQueryChange = (value: string) => {
    setSearchQuery(value);
  };

  const clearSearchQuery = () => {
    setSearchQuery('');
  };

  return previewSnippet ? (
    <SidebarSnippetPreview snippet={previewSnippet} />
  ) : (
    <SidebarTabLayout
      headerComponent={
        <SnippetsHeader
          isSearchInputOpen={isSearchInputOpen}
          searchQuery={searchQuery}
          setIsSearchInputOpen={setIsSearchInputOpen}
          setSelectedTab={setSelectedTab}
          selectedTab={selectedTab}
          handleSearchQueryChange={handleSearchQueryChange}
          sortMethod={sortMethod}
          setSortMethod={setSortMethod}
          tabs={tabs}
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
        />
      }
    >
      {searchQuery ? (
        foundSnippets.length > 0 ? (
          <SnippetsList snippets={foundSnippets} />
        ) : (
          <NoSnippetsFoundScreen
            clearSearch={clearSearchQuery}
            isClearSearchButton
          />
        )
      ) : (
        <>
          {selectedTab === SnippetsTab.TEAM && (
            <TeamSnippets
              sortMethod={sortMethod}
              selectedFilter={selectedFilter}
            />
          )}
          {selectedTab === SnippetsTab.PERSONAL && (
            <PersonalSnippets
              sortMethod={sortMethod}
              selectedFilter={selectedFilter}
            />
          )}
        </>
      )}
    </SidebarTabLayout>
  );
};
