import { JSONContent } from '@tiptap/core';

/**
 * Removes a specified property from a node if it exists and is an empty object
 *
 * @param {T} node - The node to process
 * @param {K} key - The property name to check and potentially remove (defaults to 'attrs')
 * @returns {T} - A new node with the empty property removed if applicable
 *
 * @example
 * // Remove empty attrs object
 * const cleanNode = removeEmptyObject(node);
 *
 */
const removeEmptyObject = <
  T extends Record<string, any>,
  K extends keyof T = 'attrs' & keyof T
>(
  node: T,
  key: K = 'attrs' as K
) => {
  // Check if key exists and is an empty object
  if (
    node[key] &&
    typeof node[key] === 'object' &&
    Object.keys(node[key]).length === 0
  ) {
    delete node[key];
  }

  return node;
};

/**
 * Removes empty attributes from text node marks
 *
 * @param {JSONContent} node - The text node to process
 * @returns {JSONContent} - A new text node with empty attributes removed from marks
 *
 * @example
 * // Process a text node with marks
 * const cleanTextNode = removeEmptyTextAttrs(textNode);
 */
const removeEmptyTextAttrs = (node: JSONContent) => {
  // Process marks array if it exists
  if (node.marks && Array.isArray(node.marks)) {
    node.marks = node.marks.map((mark) => removeEmptyObject(mark, 'attrs'));
  }

  return node;
};

/**
 * Normalizes a document by removing empty attribute objects
 * throughout the document tree structure
 *
 * @param {JSONContent} doc - The document to normalize
 * @returns {JSONContent} - The normalized document with empty attributes removed
 *
 * @example
 * const normalizedDoc = normalizeDoc(documentJsonContent);
 */
export function normalizeDoc(doc: JSONContent, trim = false): JSONContent {
  // Recursive function to traverse the tree.
  function traverse(node: JSONContent): void {
    // Handle text nodes specially with mark processing
    if (node.type === 'text') {
      removeEmptyTextAttrs(node);
      return;
    }

    // Remove empty attrs from non-text nodes
    removeEmptyObject(node, 'attrs');

    // Add empty content to paragraph nodes if not present
    if (node.type === 'paragraph' && !node.content) {
      node.content = [];
      return;
    }

    // Process containers
    if (node.content && Array.isArray(node.content)) {
      // Traverse children first.
      for (let i = 0; i < node.content.length; i++) {
        traverse(node.content[i]);
      }
    }
  }

  traverse(doc);

  if (trim) {
    const isTextEmpty = (doc.content ?? []).every(
      (node) =>
        ['paragraph', 'heading'].includes(node.type ?? '') &&
        (node.content?.length ?? 0) < 1
    );

    if (isTextEmpty) {
      doc.content = [];
    }
  }

  return doc;
}
