import { FC } from 'react';
import { ReactNodeViewRenderer } from '@tiptap/react';
import { SnippetsModal } from './SnippetsModal';
import { SnippetType } from '@distribute/shared/types';
import { SnippetBlockNodeView } from './SnippetBlock.renderer';
import { SnippetBlockExt } from '@distribute/shared/generate-html';

export type SnippetAlign =
  | 'left'
  | 'center'
  | 'right'
  | 'float-left'
  | 'float-right';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    snippet: {
      insertSnippet: (options: {
        id?: string;
        type: SnippetType;
      }) => ReturnType;
      toggleSnippet: (options?: { id?: string }) => ReturnType;
      renderSnippetsModal: (onDestroy?: () => void) => ReturnType;
      deleteSnippet: () => ReturnType;
      setSnippetAlign: (align: SnippetAlign) => ReturnType;
    };
  }
}

export const SnippetBlock = SnippetBlockExt.extend({
  addCommands() {
    return {
      toggleSnippet:
        () =>
        ({ commands }) => {
          return commands.toggleWrap(this.name);
        },
      insertSnippet:
        ({ id, type }) =>
        ({ commands, editor }) => {
          return commands.insertContent({
            type: this.name,
            attrs: {
              id: id || '',
              'data-media-width':
                type === SnippetType.FILE || type === SnippetType.VIDEO
                  ? editor.view.dom.offsetWidth
                  : undefined,
              'data-media-height':
                type === SnippetType.FILE || type === SnippetType.VIDEO
                  ? 640
                  : undefined,
            },
          });
        },

      setSnippetAlign:
        (align: SnippetAlign) =>
        ({ commands }) => {
          return commands.updateAttributes(this.name, { 'data-align': align });
        },

      renderSnippetsModal:
        (onDestroy) =>
        ({ editor }) => {
          const { destroyComponent } =
            editor.extensionManager.commands.renderReactComponentWithTippy(
              SnippetsModal,
              {
                onClose: () => {
                  destroyComponent();
                  onDestroy?.();
                },
                offsetWidth: editor.view.dom.offsetWidth,
                onSelect: (id: string, type: SnippetType) => {
                  destroyComponent();
                  onDestroy?.();

                  editor.chain().insertSnippet({ id, type }).focus().run();
                },
              }
            );

          return true;
        },
      deleteSnippet:
        () =>
        ({ chain, state }) =>
          chain()
            .setNodeSelection(state.selection.from)
            .deleteSelection()
            .run(),
    };
  },

  addNodeView() {
    return ReactNodeViewRenderer(SnippetBlockNodeView as FC);
  },
});
