import { JSONContent } from '@tiptap/core';

export const isRootNode = (node: JSONContent) => node.type === 'doc';

/**
 * Checks if a child node has a specific operation, either directly in its attributes
 * or within its marks.
 *
 * @param {Object} child - The child node to check
 * @param {String} operation - The operation to look for ('insert', 'delete', etc.)
 * @returns {Boolean} - Whether the child has the specified operation
 */
export const hasOperation = (
  child: JSONContent,
  operation: 'insert' | 'delete'
) => {
  return (
    child.attrs?.op === operation ||
    child.marks?.some(
      ({ type, attrs }) => type === 'diff' && attrs?.op === operation
    )
  );
};

/**
 * Filters a document to include only root-level nodes that have been edited.
 * This function extracts nodes from the diffDoc that have edit, insert, or delete operations.
 *
 * @param {JSONContent} diffDoc - The diff document generated by diffDocs
 * @returns {JSONContent} A new document containing only the edited root-level nodes
 */
export function filterRootLevelChanges(diffDoc: JSONContent): JSONContent {
  if (!diffDoc.content || !Array.isArray(diffDoc.content)) {
    return diffDoc;
  }

  // Filter for root-level nodes that have been edited
  const editedNodes = diffDoc.content.filter((node) => {
    // Check if the node itself has an operation
    return (
      node.attrs?.op === 'edit' ||
      node.attrs?.op === 'insert' ||
      node.attrs?.op === 'delete'
    );
  });

  // Create a new document with only the edited nodes
  return {
    ...diffDoc,
    content: editedNodes,
  };
}
