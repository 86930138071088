import { FC, useState } from 'react';
import cn from 'classnames';
import { SnippetCategoryWithSnippets } from '@distribute/shared/types';
import { IconMap } from '../../../../../shared/sprite';
import { Icon } from '../../../../../shared/ui';
import { SnippetsList } from './SnippetsList';

type Props = {
  category: SnippetCategoryWithSnippets;
};

export const SnippetCategory: FC<Props> = ({ category }) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <div>
      <div className="flex items-center justify-between">
        <button
          className="flex justify-center items-center gap-x-2"
          onClick={() => {
            setIsOpen((prev) => !prev);
          }}
        >
          <Icon
            glyph={IconMap.ArrowDown}
            className={cn('transition-all text-gray-600', {
              '-rotate-90': !isOpen,
            })}
          />
          <div className="flex flex-col gap-y-1">
            <div className="flex justify-center items-center gap-x-3">
              <span className="text-gray-900 text-lg font-semibold">
                {category.name}
              </span>
              <div className="bg-gray-50 border border-gray-200 rounded-full text-xs font-medium flex justify-center items-center w-5.5 h-5.5">
                {category.snippets.length}
              </div>
            </div>
          </div>
        </button>
      </div>

      {isOpen && (
        <div
          className={cn({
            'mt-3': !category.description,
            'mt-1': category.description,
          })}
        >
          {category.description && (
            <p className="text-gray-700 text-sm mb-3">{category.description}</p>
          )}
          <SnippetsList snippets={category.snippets} />
        </div>
      )}
    </div>
  );
};
