import classNames from 'classnames';
import { SpriteGlyph, Icon } from './Icon';

type Option = {
  id: string;
  label: string;
  iconName?: SpriteGlyph;
  iconWidth?: number;
  onClick?: () => void;
  disabled?: boolean;
  isComingSoon?: boolean;
};

type TabsGroupProps = {
  options: Option[];
  setActive: (val: string) => void;
  active: string;
  className?: string;
  optionClassName?: string;
};

type OptionProps = {
  option: Option;
  active: string;
  className?: string;
  setActive: (val: string) => void;
};

const Option: React.FC<OptionProps> = ({
  active,
  option,
  className,
  setActive,
}) => {
  return (
    <label
      className={classNames(
        'cursor-pointer py-2 px-5 border-t-[3px] border-gray-300 h-full flex items-center justify-center group',
        {
          'border-primary-600': active === option.id,
          'border-transparent': active !== option.id,
        },
        className
      )}
      key={option.id}
    >
      {option.iconName && (
        <Icon
          glyph={option.iconName}
          width={option.iconWidth}
          className={classNames('mr-2 group-hover:text-primary-500', {
            'text-gray-500': active !== option.id || option.disabled,
            'text-primary-500': active === option.id,
          })}
        />
      )}
      <p
        className={classNames(
          'text-md font-medium cursor-pointer group-hover:bg-base-black-8 px-2 py-0.5 rounded-md',
          {
            'text-gray-900': !option.disabled && active === option.id,
            'text-gray-600': option.disabled || active !== option.id,
          }
        )}
      >
        {option.label}
      </p>
      <input
        checked={option.label === active}
        className="hidden peer"
        id={option.id}
        type="radio"
        value={option.id}
        onChange={() => {
          if (option.disabled) return;

          setActive(option.id);
          if (option.onClick) {
            option.onClick();
          }
        }}
      />
    </label>
  );
};

export const TabsGroupTopBorder: React.FC<TabsGroupProps> = ({
  options,
  setActive,
  active,
  className,
  optionClassName,
}) => {
  return (
    <div
      className={classNames(
        'flex items-center h-10 flex-shrink-0 w-fit-content',
        className
      )}
    >
      {options.map((option, i) => (
        <Option
          key={i}
          active={active}
          option={option}
          setActive={setActive}
          className={optionClassName}
        />
      ))}
    </div>
  );
};
