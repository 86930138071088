import { useSubscriptionLimits } from '../../../../../features/subscription/hooks';
import { UpgradePlanFromSnippetsScreen } from '../../../../../pages/snippets/ui/UpgradePlanFromSnippetsScreen';
import { SnippetsContent } from './SnippetsContent';
import { SidebarTabLayout } from '../shared/SidebarTabLayout';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { editorLeftSidebarModel } from '../../../model';

export const SnippetsTab = () => {
  const dispatch = useDispatch();
  const { snippets: allowSnippets } = useSubscriptionLimits();

  useEffect(() => {
    return () => {
      dispatch(editorLeftSidebarModel.actions.setSnippetForPreview(null));
    };
  }, [dispatch]);

  return (
    <div className="w-153">
      {allowSnippets ? (
        <SnippetsContent />
      ) : (
        <SidebarTabLayout>
          <UpgradePlanFromSnippetsScreen />
        </SidebarTabLayout>
      )}
    </div>
  );
};
