import React from 'react';
import { Icon, Button } from '../../../../../shared/ui';
import { IconMap } from '../../../../../shared/sprite';

type Props = {
  isClearSearchButton?: boolean;
  clearSearch?: () => void;
};

export const NoSnippetsFoundScreen: React.FC<Props> = ({
  isClearSearchButton = false,
  clearSearch,
}) => {
  return (
    <div className="flex flex-col items-center justify-center flex-grow w-full gap-2 overflow-hidden">
      <div
        className="flex flex-col items-center justify-center w-120 h-120"
        style={{ backgroundImage: 'url(../../../assets/images/bg-dots.svg)' }}
      >
        <div className="border border-gray-200 rounded-2.5 p-3 shadow-xs w-12 h-12 bg-base-white relative">
          <Icon glyph={IconMap.Snippets} width={24} className="text-gray-700" />
        </div>
        <div className="flex flex-col items-center justify-center mt-4">
          <p className="mb-1 font-semibold text-gray-900 text-md">
            No snippets found
          </p>
          <p className="text-gray-600 text-sm font-normal text-center mb-6 max-w-74">
            We cannot find the snippet you need, but you can always create your
            own.
          </p>
          {isClearSearchButton && clearSearch && (
            <Button variant="text" color="secondary" onClick={clearSearch}>
              Clear search
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
