import { FC, KeyboardEvent, useState } from 'react';

import { IconMap } from '../../../shared/sprite';
import { ButtonSquare, Icon } from '../../../shared/ui';

import TextareaAutosize from 'react-textarea-autosize';

import { useSend } from '../hooks';

const maxLength = 2000;

export const ChatBotInput: FC = () => {
  const { send } = useSend();

  const [value, setValue] = useState('');

  const length = value.trim().length;

  const handleSubmit = () => {
    send(value.trim());
    setValue('');
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit();
    }
  };

  return (
    <div className="relative flex flex-col">
      <TextareaAutosize
        autoFocus
        maxLength={maxLength}
        value={value}
        onKeyDown={handleKeyDown}
        onChange={(e) => setValue(e.target.value)}
        placeholder="Ask anything..."
        className="p-3 pt-3 px-3 pb-14 border rounded-xl resize-none placeholder:text-gray-500 text-gray-900 focus:ring-0 focus:border-primary-600 focus:outline-none text-ellipsis disabled:bg-gray-50 disabled:text-gray-500 min-h-23 max-h-100"
      />
      <div className="absolute bottom-3 right-3 flex items-center gap-x-3 text-xs text-gray-600">
        <span className="text-xs">
          {length}/{maxLength}
        </span>
        <ButtonSquare
          variant="primary"
          className="w-8 h-8 rounded-lg"
          disabled={length === 0}
          onClick={handleSubmit}
        >
          <Icon glyph={IconMap.ArrowRight} width={20} className="-rotate-90" />
        </ButtonSquare>
      </div>
    </div>
  );
};
