import { Snippet, SnippetType } from '@distribute/shared/types';
import { FC } from 'react';
import { SidebarTabLayout } from '../shared/SidebarTabLayout';
import { Button, Icon } from '../../../../../shared/ui';
import { IconMap } from '../../../../../shared/sprite';
import { useDispatch } from 'react-redux';
import {
  FilePreview,
  ImagePreview,
  snippetsModel,
  TextPreview,
  VideoPreview,
  snippetModalTitleByType,
} from '../../../../../features/snippets';
import { useTiptapEditor } from '../../../../../entities/tiptap-editor';
import { editorLeftSidebarModel } from '../../../model';

type Props = {
  snippet: Snippet;
};

export const SidebarSnippetPreview: FC<Props> = ({ snippet }) => {
  const dispatch = useDispatch();

  const { editor } = useTiptapEditor();

  const tags = [
    snippet.category ? snippet.category.name : 'Personal',
    snippet.type,
  ];
  return (
    <SidebarTabLayout
      headerComponent={
        <div className="flex items-center justify-between">
          <Button
            size="md"
            color="secondary"
            variant="icon"
            onClick={() =>
              dispatch(
                editorLeftSidebarModel.actions.setSnippetForPreview(null)
              )
            }
          >
            <Icon glyph={IconMap.ArrowLeft} width={20} />
          </Button>
          <Button
            size="md"
            color="primary"
            variant="text"
            onClick={() => {
              dispatch(
                snippetsModel.actions.updateSnippetUsedCount({
                  snippetId: snippet.id,
                })
              );

              if (editor) {
                editor
                  .chain()
                  .insertSnippet({ id: snippet.id, type: snippet.type })
                  .focus()
                  .run();
              }
            }}
          >
            Insert Snippet
          </Button>
        </div>
      }
    >
      <div className="flex items-center gap-3 mb-1">
        <div className="flex items-center justify-center w-8 h-8 border rounded-md bg-base-white border-light-7">
          <Icon
            glyph={snippetModalTitleByType[snippet.type].icon}
            className="text-gray-600"
            width={20}
          />
        </div>
        <p className="text-2xl font-semibold text-gray-900 truncate ">
          {snippet.name}
        </p>
      </div>
      <p className="text-sm text-gray-700">{snippet.description}</p>
      <div className="flex flex-wrap gap-1 mt-3 mb-4">
        {tags.map((tag) => (
          <div className="capitalize px-2 py-0.75 bg-base-white rounded-md border border-gray-300 text-gray-700 text-xs font-medium">
            {tag}
          </div>
        ))}
      </div>
      <div className="flex-grow overflow-x-hidden">
        {snippet.type === SnippetType.TEXT && <TextPreview snippet={snippet} />}
        {snippet.type === SnippetType.IMAGE && (
          <ImagePreview snippet={snippet} />
        )}
        {snippet.type === SnippetType.VIDEO && (
          <VideoPreview snippet={snippet} />
        )}
        {snippet.type === SnippetType.FILE && <FilePreview snippet={snippet} />}
      </div>
    </SidebarTabLayout>
  );
};
