import { withStrictError } from '../../../shared/lib';
import { RootState } from '../../../app';

import {
  ChatResponseType,
  ChatStructuredSuggestionData,
} from '@distribute/shared/types';

import { ConversationState } from './types';

export const selectChats = ({ chat }: RootState) => chat.chats;

export const selectIsChatLoading = ({ chat }: RootState) => chat.isChatLoading;
export const selectIsChatDeleting = ({ chat }: RootState) =>
  chat.isChatDeleting;

export const selectIsChatDeleteModalOpen = ({ chat }: RootState) =>
  chat.isChatDeleteModalOpen;

export const selectConversationState = ({ chat }: RootState) =>
  chat.conversationState;

export const selectConversationIsActive = ({ chat }: RootState) =>
  [ConversationState.Streaming, ConversationState.Suggestion].includes(
    chat.conversationState
  );

export const selectCurrentChat = ({ chat }: RootState) => chat.currentChat;

export const selectCurrentChatWithError = ({ chat }: RootState) =>
  withStrictError(chat.currentChat, 'currentChat');

export const selectConversation = ({ chat }: RootState) => chat.conversation;

export const selectConversationWithError = ({ chat }: RootState) =>
  withStrictError(chat.conversation, 'conversation');

export const selectLoadingMessageIds = ({ chat }: RootState) =>
  chat.loadingMessageIds;

export const selectConversationSuggestions = ({ chat }: RootState) =>
  (chat.conversation?.structuredResponse.filter(
    (s) => s.type === ChatResponseType.Suggestion && s.changed
  ) ?? []) as ChatStructuredSuggestionData[];

export const selectIsSuggestionMode = ({ chat }: RootState) =>
  chat.conversationState === ConversationState.Suggestion;
