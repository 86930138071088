import { Snippet } from '@distribute/shared/types';
import { FC } from 'react';
import { SnippetItem } from './SnippetItem';

type Props = {
  snippets: Snippet[];
};

export const SnippetsList: FC<Props> = ({ snippets }) => {
  return (
    <div className="grid flex-grow grid-cols-2 gap-3 overflow-x-hidden overflow-y-auto place-content-start">
      {snippets.map((snippet) => (
        <SnippetItem key={snippet.id} snippet={snippet} />
      ))}
    </div>
  );
};
