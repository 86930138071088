import { JSONContent } from '@tiptap/core';
import { EMPTY } from './markEmptyNodes';
import { isRootNode } from './utils';

const END_OF_BLOCK = '\uE000';

function addBlockSeparators(doc: JSONContent): JSONContent {
  // Recursive function to traverse the tree.
  function traverse(node: JSONContent): void {
    // Skip nodes marked as empty.
    if (node[EMPTY]) return;

    // Process containers
    if (node.content && Array.isArray(node.content)) {
      // Traverse children first.
      for (let i = 0; i < node.content.length; i++) {
        traverse(node.content[i]);
      }
      // If node type is whitelisted, append the end-of-block token.
      if (!isRootNode(node)) {
        node.content.push({ type: 'text', text: END_OF_BLOCK });
      }
    }
  }

  traverse(doc);
  return doc;
}

export { addBlockSeparators, END_OF_BLOCK };
