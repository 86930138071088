import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { snippetsModel } from '../../../../../features/snippets';
import { SnippetType } from '@distribute/shared/types';
import {
  getFilteredSnippets,
  getSortedSnippets,
} from '../../../../../pages/snippets/lib';
import { SortState } from './lib';
import { SnippetCategory } from './SnippetCategory';
import { NoSnippetsFoundScreen } from './NoSnippetsFoundScreen';

type Props = {
  sortMethod: SortState;
  selectedFilter: SnippetType | undefined;
};

export const TeamSnippets: React.FC<Props> = ({
  sortMethod,
  selectedFilter,
}) => {
  const snippetCategories = useSelector(
    snippetsModel.selectors.selectCategories
  );

  const currentSnippetsToShow = useMemo(() => {
    const filteredSnippets = snippetCategories
      .map((category) => {
        return {
          ...category,
          snippets: getFilteredSnippets(category.snippets, '', selectedFilter),
        };
      })
      .filter((c) => c.snippets.length > 0);

    const sortedSnippets = filteredSnippets.map((c) => {
      return {
        ...c,
        snippets: getSortedSnippets(c.snippets, sortMethod),
      };
    });

    return sortedSnippets;
  }, [snippetCategories, selectedFilter, sortMethod]);

  return currentSnippetsToShow.length ? (
    <div className="flex flex-col gap-y-8">
      {currentSnippetsToShow.map((category) => (
        <SnippetCategory category={category} key={category.id} />
      ))}
    </div>
  ) : (
    <NoSnippetsFoundScreen />
  );
};
