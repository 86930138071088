import React, {
  MutableRefObject,
  forwardRef,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { mergeRefs } from 'react-merge-refs';
import classNames from 'classnames';

import { useTiptapEditor } from '../../../../entities/tiptap-editor';
import { Icon, SpriteGlyph } from '../../../../shared/ui/Icon';
import { IconMap } from '../../../../shared/sprite';
import { editorSidebarModel } from '../../../../features/editor-sidebar';
import { environment } from '../../../../environments/environment';
import { FeatureFlags } from '../../../../entities/feature-flag';
import { useSubscriptionLimits } from '../../../../features/subscription/hooks';
import { checkIsEmptyContent } from '../../../../entities/tiptap-editor';
import { pageCreationFlowModel } from '../../../../processes/page-creation-flow';
import {
  editorLeftSidebarModel,
  LeftSidebarOptions,
} from '../../../../features/editor-left-sidebar';

type MenuItem = {
  id: string;
  label: string;
  icon: SpriteGlyph | string;
  ref?: MutableRefObject<HTMLDivElement | null>;
  onClick: (item: MenuItem) => void;
  hidden?: boolean;
};

type MenuCategory = {
  id: string;
  items: MenuItem[];
  title?: string;
};

type IProps = {
  onOpenTemplatesModal: () => void;
  isTemplateMode: boolean;
  isSnippetMode?: boolean;
};

export const EmptyPageShortcuts = forwardRef<HTMLDivElement, IProps>(
  ({ onOpenTemplatesModal, isTemplateMode, isSnippetMode }, ref) => {
    const [isForceHide, setIsForceHide] = useState(false);
    const { editor } = useTiptapEditor();

    const pageContent = editor?.getJSON();
    const isPageEmpty = checkIsEmptyContent(pageContent);
    const isHideShortcuts = !isPageEmpty || isForceHide;
    const dropdownContentRef = useRef<HTMLDivElement | null>(null);
    const firstMenuItemRef = useRef<HTMLDivElement | null>(null);

    const { snippets: allowSnippets } = useSubscriptionLimits();

    const dispatch = useDispatch();

    const menuCategories = useMemo<MenuCategory[]>(
      () => [
        {
          id: 'general',
          items: [
            {
              id: 'emptyPage',
              label: 'Empty page',
              icon: IconMap.File04,
              ref: firstMenuItemRef,
              onClick: () => {
                setIsForceHide(true);
                editor?.commands.focus();
              },
            },
            {
              id: 'ai',
              label: 'Start writing with AI...',
              icon: '../../../../assets/images/wizard-write.svg',
              onClick: () => {
                if (isTemplateMode) {
                  editor?.commands.renderAI(() => setIsForceHide(false));
                  setIsForceHide(true);
                } else {
                  dispatch(
                    pageCreationFlowModel.actions.openPageCreationFlow({
                      mode: 'tab',
                    })
                  );
                }
              },
            },
          ],
        },
        {
          id: 'addNew',
          title: 'Add new',
          items: [
            {
              id: 'templates',
              label: 'Templates',
              icon: IconMap.LayersThree01,
              onClick: () => {
                dispatch(
                  editorLeftSidebarModel.actions.setSelectedOption(
                    LeftSidebarOptions.TEMPLATES
                  )
                );
              },
              hidden: isTemplateMode || isSnippetMode,
            },
            {
              id: 'snippets',
              label: 'Snippets',
              icon: IconMap.Snippets,
              onClick: () => {
                dispatch(
                  editorLeftSidebarModel.actions.setSelectedOption(
                    LeftSidebarOptions.SNIPPETS
                  )
                );
              },
              hidden:
                isSnippetMode ||
                !environment.featureFlags[FeatureFlags.SNIPPETS] ||
                !allowSnippets,
            },
            {
              id: 'table',
              label: 'Table',
              icon: IconMap.Table,
              onClick: () => {
                editor?.commands.renderTableSizeSelector(() =>
                  setIsForceHide(false)
                );
                setIsForceHide(true);
              },
            },
            {
              id: 'bulletedList',
              label: 'Bulleted list',
              icon: IconMap.ListBulleted,
              onClick: () => editor?.chain().focus().toggleBulletList().run(),
            },
            {
              id: 'numberedList',
              label: 'Numbered list',
              icon: IconMap.ListNumbered,
              onClick: () => editor?.chain().focus().toggleOrderedList().run(),
            },
            {
              id: 'image',
              label: 'Image',
              icon: IconMap.Image01,
              onClick: () => {
                editor?.commands.renderImageUploadForm(() =>
                  setIsForceHide(false)
                );
                setIsForceHide(true);
              },
            },
            {
              id: 'video',
              label: 'Video',
              icon: IconMap.Youtube,
              onClick: () => {
                editor?.commands.renderVideoUploadForm(() =>
                  setIsForceHide(false)
                );
                setIsForceHide(true);
              },
            },
          ],
        },
      ],
      [editor, onOpenTemplatesModal, isTemplateMode, isSnippetMode, dispatch]
    );

    useEffect(() => {
      if (!isPageEmpty) {
        setIsForceHide(false);
      }
    }, [isPageEmpty]);

    const shouldFocusSlugInput = useSelector(
      editorSidebarModel.selectors.selectShouldFocusSlugInput
    );

    useEffect(() => {
      if (isHideShortcuts) return;

      if (shouldFocusSlugInput) {
        return;
      }

      const timeoutId = setTimeout(() => {
        dropdownContentRef.current?.focus();
        firstMenuItemRef.current?.focus();
      });

      return () => clearTimeout(timeoutId);
    }, [isHideShortcuts]);

    if (isHideShortcuts) return null;

    return (
      <div className="relative z-10">
        <div className="absolute left-0 top-0 w-full">
          <DropdownMenu.Root open modal={false}>
            <DropdownMenu.Trigger asChild>
              <div />
            </DropdownMenu.Trigger>
            <div className="dropdown-content-relative-wrapper">
              <DropdownMenu.Content
                loop
                asChild
                ref={mergeRefs([ref, dropdownContentRef])}
                side="bottom"
                align="start"
              >
                <div className="flex flex-col gap-6 bg-base-white">
                  {menuCategories.map((category) => (
                    <div key={category.id} className="flex flex-col gap-4">
                      {category.title && (
                        <div className="text-sm font-medium text-gray-500">
                          {category.title}
                        </div>
                      )}
                      <div className="flex flex-col gap-1">
                        {category.items.map((item) => (
                          <DropdownMenu.Item
                            key={item.id}
                            ref={item.ref}
                            className={classNames('group outline-none', {
                              hidden: item.hidden,
                            })}
                            onSelect={(e) => {
                              if (
                                (e.target as HTMLDivElement)?.dataset
                                  .highlighted !== undefined
                              ) {
                                item.onClick(item);
                              }
                            }}
                          >
                            <div className="flex items-center gap-x-3 p-2 bg-base-white text-md font-medium text-gray-500 rounded-lg cursor-pointer group-data-[highlighted]:bg-gray-75 group-data-[highlighted]:text-gray-700">
                              {typeof item.icon === 'string' ? (
                                <img
                                  src={item.icon}
                                  className="w-5"
                                  alt={item.label}
                                />
                              ) : (
                                <Icon
                                  glyph={item.icon}
                                  width={20}
                                  className="text-gray-500"
                                />
                              )}
                              <span className="grow-1 truncate">
                                {item.label}
                              </span>
                              <Icon
                                glyph={IconMap.EnterKey}
                                width={16}
                                className="text-gray-400 shrink-0 hidden group-data-[highlighted]:flex"
                              />
                            </div>
                          </DropdownMenu.Item>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </DropdownMenu.Content>
            </div>
          </DropdownMenu.Root>
        </div>
      </div>
    );
  }
);

EmptyPageShortcuts.displayName = 'EmptyPageShortcuts';
