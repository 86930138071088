import {
  GenerateChatMessageRequest,
  UpdateChatMessageRequest,
} from '@distribute/shared/api-types/chat';
import {
  ChatStructuredData,
  DocumentContentItem,
} from '@distribute/shared/types';

export type GetChatsAction = {
  entityId: string;
};

export type GenerateMessageAction = {
  prompt: string;
  tabs: Record<string, DocumentContentItem>;
  title: string;
  currentTabId: number;
  isBranded: boolean;
};

export type StopGenerateMessageAction = {
  id: string;
};

export type UpvoteMessageAction = {
  chatId: string;
  messageId: string;
  data: UpdateChatMessageRequest;
};

export type ChatConversation = {
  id: string;
  request: GenerateChatMessageRequest;
  response: string;
  structuredResponse: ChatStructuredData[];
};

export enum ConversationState {
  Idle = 'idle',
  Streaming = 'streaming',
  Suggestion = 'suggestion',
}
