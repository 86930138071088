import { SnippetType } from '@distribute/shared/types';

export enum SortState {
  LAST_CREATED = 'last-created',
  MOST_POPULAR = 'most-popular',
  NAME = 'name',
}

export enum SnippetsTab {
  TEAM = 'team',
  PERSONAL = 'personal',
}

export const filters: {
  label: string;
  id: SnippetType | 'all-snippets';
}[] = [
  {
    label: 'All',
    id: 'all-snippets',
  },
  {
    label: 'Text',
    id: SnippetType.TEXT,
  },
  {
    label: 'Image',
    id: SnippetType.IMAGE,
  },
  {
    label: 'Video',
    id: SnippetType.VIDEO,
  },
  {
    label: 'File',
    id: SnippetType.FILE,
  },
];
