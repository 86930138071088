import { useCollaboration } from '../../../../../../entities/collaboration';
import { customDomainsModel } from '../../../../../../features/custom-domains';
import { pagesModel } from '../../../../../../features/pages';
import {
  createNotification,
  snackbarModel,
} from '../../../../../../features/snackbar';
import { teamsModel } from '../../../../../../features/teams';
import { getFullPageUrl, getPageGmailUrl } from '../../../../../../shared/lib';
import { Button, Modal } from '../../../../../../shared/ui';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

type Props = {
  onClose: () => void;
  isOpen: boolean;
};

export const PublishPageModal: FC<Props> = ({ onClose, isOpen }) => {
  const dispatch = useDispatch();

  const { updatePublished } = useCollaboration();

  const currentPage = useSelector(
    pagesModel.selectors.selectCurrentPageWithError
  );

  const { domain: currentTeamDomain } = useSelector(
    teamsModel.selectors.selectCurrentTeamWithError
  );

  const customDomain = useSelector(
    customDomainsModel.selectors.selectVerifiedCustomDomain
  );

  const publishPageAndCopyToGmail = () => {
    updatePublished(true);
    const pageUrl = getFullPageUrl({
      slug: currentPage.pageSlug,
      domain: currentTeamDomain,
      customDomain,
    });

    const pageGmailUrl = getPageGmailUrl({
      url: pageUrl,
      id: currentPage.id,
      gifImage: currentPage.gifImage?.toString() || 'null',
      title: currentPage.content.title ?? '',
    });

    onClose();

    window.open(pageGmailUrl, '_blank');
  };

  const handleClose = () => {
    onClose();
    dispatch(
      snackbarModel.actions.addNotificationAction(
        createNotification(
          'warning',
          'The link has not been copied because the page is not yet published for viewing'
        )
      )
    );
  };

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      title="Make page public"
      description="The Gmail link requires the page to be public; otherwise, it will be unavailable to users. Would you like to make this page public?"
      isShowCancelButton={false}
    >
      <div className="flex gap-3">
        <Button
          fullWidth
          color="secondary"
          variant="text"
          type="button"
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          fullWidth
          color="primary"
          variant="text"
          type="button"
          onClick={publishPageAndCopyToGmail}
        >
          Publish page
        </Button>
      </div>
    </Modal>
  );
};
