import React from 'react';
import cn from 'classnames';
import { Snippet } from '@distribute/shared/types';

import { useDispatch } from 'react-redux';
import { Button, Icon } from '../../../../../shared/ui';
import { snippetsModel } from '../../../../../features/snippets';
import { IconMap } from '../../../../../shared/sprite';
import { SnippetPreview } from '../../../../../features/snippets/ui/components';
import { snippetItemIconByType } from '../../../../../features/snippets/ui/components/lib/config';
import { CreatedByRow } from '../../../../../features/snippets/ui/components/CreatedByRow';
import { useTiptapEditor } from '../../../../../entities/tiptap-editor';
import { editorLeftSidebarModel } from '../../../model';

type Props = {
  snippet: Snippet;
};

export const SnippetItem: React.FC<Props> = ({ snippet }) => {
  const dispatch = useDispatch();
  const { editor } = useTiptapEditor();

  return (
    <div className="p-3 border border-gray-300 rounded-xl h-fit group w-70 hover:shadow-md ">
      <div
        className={cn(
          'relative flex items-center justify-center mb-3 overflow-hidden h-41 rounded-md'
        )}
      >
        <div className="absolute z-10 flex flex-col items-center justify-center w-full h-full gap-2 transition-all duration-100 ease-linear opacity-0 group-hover:opacity-100 sm:hidden">
          <Button
            variant="text"
            color="primary"
            className="w-45 !h-11 text-md"
            onClick={() => {
              dispatch(
                snippetsModel.actions.updateSnippetUsedCount({
                  snippetId: snippet.id,
                })
              );

              if (editor) {
                editor
                  .chain()
                  .insertSnippet({ id: snippet.id, type: snippet.type })
                  .focus()
                  .run();
              }
            }}
          >
            Insert Snippet
          </Button>
          <Button
            variant="text"
            color="secondary"
            className="w-45 !h-9 text-sm"
            onClick={() => {
              dispatch(
                editorLeftSidebarModel.actions.setSnippetForPreview(snippet)
              );
            }}
          >
            <Icon glyph={IconMap.Eye} width={20} className="mr-1.5" />
            Preview
          </Button>
        </div>
        <SnippetPreview snippet={snippet} />
      </div>

      <div className="flex items-center mb-2 gap-x-2">
        <Icon
          glyph={snippetItemIconByType[snippet.type]}
          width={20}
          className="text-gray-600 min-w-5"
        />
        <p className="font-semibold truncate text-md">
          {snippet.name || 'Untitled'}
        </p>
      </div>
      <div className="flex items-center justify-between">
        <CreatedByRow owner={snippet.owner} />
      </div>
    </div>
  );
};
