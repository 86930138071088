import { Dispatch, FC, SetStateAction, useMemo } from 'react';
import { IconMap } from '../../../../../shared/sprite';
import {
  Button,
  Dropdown,
  Icon,
  SearchInput,
} from '../../../../../../src/shared/ui';
import { Tab, TabsSwitcher } from '../shared';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { filters, SortState } from './lib';
import { SnippetType } from '@distribute/shared/types';
import { cn } from '@distribute/frontend/utils';

type Props = {
  isSearchInputOpen: boolean;
  searchQuery: string;
  setIsSearchInputOpen: Dispatch<SetStateAction<boolean>>;
  setSelectedTab: Dispatch<SetStateAction<string>>;
  selectedTab: string;
  handleSearchQueryChange: (value: string) => void;
  sortMethod: SortState;
  setSortMethod: Dispatch<SetStateAction<SortState>>;
  tabs: Tab[];
  selectedFilter: SnippetType | undefined;
  setSelectedFilter: Dispatch<SetStateAction<SnippetType | undefined>>;
};

export const SnippetsHeader: FC<Props> = ({
  selectedTab,
  setSelectedTab,
  isSearchInputOpen,
  setIsSearchInputOpen,
  searchQuery,
  handleSearchQueryChange,
  sortMethod,
  setSortMethod,
  tabs,
  selectedFilter,
  setSelectedFilter,
}) => {
  const mockSortDropdownItems = useMemo(() => {
    return [
      {
        label: 'Last Created',
        onClick: () => {
          setSortMethod(SortState.LAST_CREATED);
        },
        id: SortState.LAST_CREATED,
      },
      {
        label: 'Most Popular',
        onClick: () => {
          setSortMethod(SortState.MOST_POPULAR);
        },
        id: SortState.MOST_POPULAR,
      },
      {
        label: 'Name',
        onClick: () => {
          setSortMethod(SortState.NAME);
        },
        id: SortState.NAME,
      },
    ];
  }, [setSortMethod]);

  return (
    <div>
      <div className="flex items-center gap-2 mb-4">
        {isSearchInputOpen ? (
          <div className="flex w-full gap-2">
            <div className="relative w-full">
              <SearchInput
                value={searchQuery}
                onChange={handleSearchQueryChange}
                placeholder="Search"
              />
            </div>
            <Button
              size="md"
              color="transparent"
              variant="icon"
              onClick={() => setIsSearchInputOpen(false)}
            >
              <Icon glyph={IconMap.XClose} width={20} />
            </Button>
          </div>
        ) : (
          <>
            <TabsSwitcher
              tabs={tabs}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
            />
            <Button
              color="secondary"
              variant="icon"
              size="md"
              onClick={() => setIsSearchInputOpen(true)}
            >
              <Icon glyph={IconMap.SearchLg} width={20} />
            </Button>
            <Dropdown
              listStyles="shadow-lg"
              items={mockSortDropdownItems}
              currentItemId={sortMethod}
              itemStyles="min-w-37.5 sm:min-w-full"
              arrowStyle="text-gray-800"
              triggerComponent={
                <DropdownMenu.Trigger className="flex items-center justify-center w-10 h-10 border border-gray-300 rounded-md shadow-xs min-w-10">
                  <Icon
                    glyph={IconMap.SwitchVertical01}
                    width={20}
                    className="text-gray-800"
                  />
                </DropdownMenu.Trigger>
              }
            />
          </>
        )}
      </div>
      {!searchQuery && (
        <div className="flex items-center gap-2">
          {filters.map((filter) => (
            <button
              className={cn(
                'w-18 h-9 border rounded-full flex justify-center items-center text-sm font-medium',
                {
                  'text-gray-900 border-gray-400 bg-gray-200':
                    filter.id === selectedFilter ||
                    (filter.id === 'all-snippets' && !selectedFilter),
                  'text-gray-700 border-gray-300 bg-base-white':
                    filter.id !== selectedFilter && selectedFilter,
                }
              )}
              onClick={() => {
                if (filter.id === 'all-snippets') {
                  setSelectedFilter(undefined);
                  return;
                }
                setSelectedFilter(filter.id);
              }}
            >
              {filter.label}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};
