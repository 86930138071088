import { FeatureFlags } from '../entities/feature-flag';
import { Environment } from './types';

export const environment: Environment = {
  env: 'staging',
  firebaseConfig: {
    apiKey: 'AIzaSyCLkNq5WhES5WDwwtdidoBi2lH0r-ac5mc',
    authDomain: 'distribute-staging.firebaseapp.com',
    projectId: 'distribute-staging',
    storageBucket: 'distribute-staging.appspot.com',
    messagingSenderId: '431805320214',
    appId: '1:431805320214:web:77ead3ef3b6c9f84f3b937',
  },
  sentryDsn:
    'https://c80cc6a6b21c49378ec0dcb7e9a1e3e0@o4504050707726336.ingest.sentry.io/4504050731057152',
  apiUrl: 'https://api.distribute-staging.com/api',
  dataProcessingApiUrl: 'https://data-processing-api.distribute-staging.com',
  staticBaseUrl: 'https://storage.distribute-staging.com',
  staticBaseTranscoderUrl:
    'https://storage.googleapis.com/distribute-transcoder-storage',
  appHost: 'distribute-staging.com',
  appOrigin: 'distribute-staging.com',
  segmentWriteKey: '3u5NplEiuJxcxXBgNAnziFSEVG9Sr4hs',
  templateSubmitLink: 'https://survey.typeform.com/to/vqZFKWvO',
  gleapApiKey: 'fyjQVBxJ9EFuC6KEEUPb6v26U3MeHvI9',
  featureFlags: {
    [FeatureFlags.ZAPIER]: true,
    [FeatureFlags.DISTRIBUTE_TAB]: true,
    [FeatureFlags.PERSONALIZED_LINKS]: false,
    [FeatureFlags.SNIPPETS]: true,
    [FeatureFlags.HTML_CONTENT]: true,
    [FeatureFlags.DOCUMENT_COLLABORATION]: true,
    [FeatureFlags.NARRATION]: true,
    [FeatureFlags.CONTACT_CARD]: true,
    [FeatureFlags.CALL_RECORDING]: true,
    [FeatureFlags.NEW_EDITOR]: true,
    [FeatureFlags.CHAT_BOT]: true,
  },
  stripePublishableKey:
    'pk_test_51Lz0TbDGP8bK7ki5LBZfAfmJM6ucvqLZet6k570XKkmBYfzG5PuqlbixpOItsW9UzRvYf7z8qYjtKKXi20WI2deQ002RExyZwd',
  landingUrl: 'https://distribute.so',
  linkedinClientId: '77lv9pnkraae3b',
  extensionId: 'mnkancbbdmaleennphembmhlikhiajoc',
  extensionLocalId: 'klmhmlgheaalldfigmnilmejcjogfogi',
  extensionWebsiteUrl:
    'https://chromewebstore.google.com/u/0/detail/distribute-beta/mnkancbbdmaleennphembmhlikhiajoc',
  clearbitApiUrl: 'https://autocomplete.clearbit.com/v1/companies/suggest',
  gongClientId: 'azfehrrte6g',
  collaborationServerUrl: 'stage.distribute-collaboration.com',
  collaborationRestUrl: 'https://stage.distribute-collaboration.com',
  gtmId: '',
};
