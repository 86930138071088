import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { SearchInput, SearchSuggestion } from './SearchInput';
import { useTiptapEditor } from '../../lib';
import { AIDraftsMenu } from './AIDraftsMenu';
import { AIDraftItem } from '../../config';
import { ActionsAfterWriting } from './ActionsAfterWriting';
import { WritingActions } from './WritingActions';
import { AIStartedWritingFrom, EditorWritingMode } from './types';
import { DiscardResponseModal } from './DiscardResponseModal';
import { useDispatch, useSelector } from 'react-redux';
import { aiModel } from '../../../../features/ai';
import { AIWritingActionType } from '@distribute/shared/api-types/ai';
import { pagesModel } from '../../../../features/pages';

import { AIErrorHandler } from './AIErrorHandler';
import { useEditorContextSelection } from './hooks';

import { SearchInputWrapper } from './SearchInputWrapper';
import { useAIScroll } from './hooks';
import { redirectActions } from '../../../history';
import { templatesModel } from '../../../../features/templates';

import { checkIsWriteMode } from './lib';
import { useCollaboration } from '../../../collaboration';
import { DocumentContentItem } from '@distribute/shared/types';
import { useEditorWriting } from './hooks/useEditorWriting';
import { getIsContentEmpty } from '../../utils';

enum ActionOnConfirmDiscardResponse {
  REDIRECT_TO_SUBSCRIPTION,
}

type IProps = {
  startedWritingFrom: AIStartedWritingFrom;
  onClose: () => void;
};

const PAGE_TOPIC_SUGGESTION = 'page topic';

export const AI: FC<IProps> = ({ onClose, startedWritingFrom }) => {
  const dispatch = useDispatch();

  const currentPage = useSelector(pagesModel.selectors.selectCurrentPage);

  const currentTemplate = useSelector(
    templatesModel.selectors.selectCurrentTemplate
  );

  const responses = useSelector(aiModel.selectors.selectResponses);
  const isLoading = useSelector(aiModel.selectors.selectIsLoading);
  const isGeneratingMultiTabs = useSelector(
    aiModel.selectors.selectIsGeneratingMultiTabs
  );

  const { editor } = useTiptapEditor();

  const refContainer = useRef<HTMLDivElement>(null);
  const refSearchInput = useRef<HTMLTextAreaElement | null>(null);
  const menuRef = useRef<HTMLDivElement | null>(null);

  const [isShowDiscardResponseModal, setIsShowDiscardResponseModal] =
    useState(false);
  const [actionOnConfirmDiscardResponse, setActionOnConfirmDiscardResponse] =
    useState<ActionOnConfirmDiscardResponse | null>(null);
  const [searchValue, setSearchValue] = useState('');
  const [searchSuggestion, setSearchSuggestion] = useState<
    SearchSuggestion | undefined
  >();

  const [AIWritingAction, setAIWritingAction] =
    useState<AIWritingActionType | null>(null);

  const [currentActiveResponseIdx, setCurrentActiveResponseIdx] = useState(
    responses.length - 1
  );

  const selectNextResponse = useCallback(() => {
    const newCurrentResponseIdx =
      currentActiveResponseIdx === responses.length - 1
        ? 0
        : currentActiveResponseIdx + 1;
    setCurrentActiveResponseIdx(newCurrentResponseIdx);
  }, [currentActiveResponseIdx, responses.length]);

  const selectPrevResponse = useCallback(() => {
    const newCurrentResponseIdx =
      currentActiveResponseIdx === 0
        ? responses.length - 1
        : currentActiveResponseIdx - 1;
    setCurrentActiveResponseIdx(newCurrentResponseIdx);
  }, [currentActiveResponseIdx, responses.length]);

  const [editorWritingMode, setEditorWritingMode] = useState<EditorWritingMode>(
    () =>
      checkIsWriteMode(startedWritingFrom)
        ? EditorWritingMode.Editor
        : EditorWritingMode.ReadonlyEditor
  );

  const currentResponse = responses[currentActiveResponseIdx];

  const [focusedElement, setFocusedElement] = useState<'menu' | 'input'>(
    'menu'
  );

  const onFocusMenu = useCallback(() => {
    menuRef.current?.focus();
  }, []);

  const onFocusInput = useCallback(() => {
    refSearchInput.current?.focus();
  }, []);

  useEffect(() => {
    setTimeout(onFocusInput, 0);
  }, [onFocusInput]);

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      const isLeftRightArrowTriggered =
        e.key === 'ArrowLeft' || e.key === 'ArrowRight';

      const isUpDownArrowTriggered =
        e.key === 'ArrowDown' || e.key === 'ArrowUp';

      const isSpace = e.code === 'Space';

      if (isLeftRightArrowTriggered) {
        if (searchValue.length === 0) {
          if (e.key === 'ArrowRight') {
            selectNextResponse();
          }

          if (e.key === 'ArrowLeft') {
            selectPrevResponse();
          }
        }
        return;
      }

      if (focusedElement === 'input' && isUpDownArrowTriggered) {
        onFocusMenu();
      }

      if (focusedElement === 'menu' && !isUpDownArrowTriggered) {
        onFocusInput();
      }

      if (focusedElement === 'menu' && isSpace) {
        setSearchValue((val) => val + ' ');
      }
    };

    window.addEventListener('keydown', listener);

    return () => {
      window.removeEventListener('keydown', listener);
    };
  }, [
    focusedElement,
    onFocusInput,
    onFocusMenu,
    searchValue.length,
    selectNextResponse,
    selectPrevResponse,
  ]);

  const editorWidth = useMemo(() => {
    if (!editor) return 0;
    const paddingLeft = Number.parseInt(
      getComputedStyle(editor.view.dom).paddingLeft
    );
    return editor.view.dom.clientWidth - paddingLeft;
  }, [editor]);

  useEffect(() => {
    if (!editor) {
      return;
    }

    editor.storage.ai.isShowInline = checkIsWriteMode(startedWritingFrom);
  }, [editor, startedWritingFrom]);

  useEffect(() => {
    setEditorWritingMode(
      checkIsWriteMode(startedWritingFrom)
        ? EditorWritingMode.Editor
        : EditorWritingMode.ReadonlyEditor
    );
  }, [startedWritingFrom]);

  const { isCollaborationEnabled, provider } = useCollaboration();

  const setCurrentContentItem = useCallback(
    (contentItem: DocumentContentItem) => {
      if (!currentPage) {
        return;
      }
      dispatch(
        pagesModel.actions.setCurrentContentItem({ contentItem: contentItem })
      );

      if (isCollaborationEnabled) {
        provider?.awareness.setLocalStateField('activeTabId', contentItem.id);
      }
    },
    [currentPage, dispatch, isCollaborationEnabled, provider?.awareness]
  );

  const { requestContext, setSelection, selectionFromRef, selectionToRef } =
    useEditorContextSelection({ startedWritingFrom });

  const { currentRequestContent, onInsertEditorContent } = useEditorWriting({
    resultText: currentResponse?.resultText || '',
    editorWritingMode,
    selectionFromRef,
    selectionToRef,
    setSelection,
    setFocus: onFocusInput,
    setSearchValue,
    contentItems: currentPage ? currentPage.content.contentItems : undefined,
    setCurrentContentItem: setCurrentContentItem,
  });

  useEffect(() => {
    setCurrentActiveResponseIdx(responses.length - 1);
  }, [responses.length]);

  const onDone = () => {
    editor?.commands.setTextSelection({
      from: selectionToRef.current,
      to: selectionToRef.current,
    });
    editor?.chain().focus().enter().run();
    onCloseAI();
  };

  const redirectToSubscriptionPage = useCallback(() => {
    dispatch(redirectActions.toSubscriptionsClick());
    editor?.commands.setTextSelection(editor.state.selection.to);
  }, [dispatch]);

  const onCloseAI = useCallback(() => {
    dispatch(aiModel.actions.clearFlow());
    editor?.commands.focus();
    if (
      actionOnConfirmDiscardResponse ===
      ActionOnConfirmDiscardResponse.REDIRECT_TO_SUBSCRIPTION
    ) {
      redirectToSubscriptionPage();
    }
    onClose();
  }, [
    actionOnConfirmDiscardResponse,
    dispatch,
    onClose,
    redirectToSubscriptionPage,
    editor,
  ]);

  const clickOutsideHandler = useCallback(() => {
    const shouldShowDiscardResponseModal =
      editorWritingMode === EditorWritingMode.Editor
        ? isLoading
        : !!AIWritingAction || isLoading;

    if (shouldShowDiscardResponseModal) {
      setIsShowDiscardResponseModal(true);
      return;
    }

    onCloseAI();
  }, [AIWritingAction, isLoading, onCloseAI, editorWritingMode]);

  useEffect(() => {
    dispatch(aiModel.actions.setIsAIInputRendered(true));

    return () => {
      if (isGeneratingMultiTabs) {
        onClose();
      } else {
        dispatch(aiModel.actions.clearFlow());
      }
    };
  }, [dispatch, isGeneratingMultiTabs, onClose]);

  const handleSelectDraft = (item: AIDraftItem) => {
    setSearchValue(item.text);
    if (currentPage?.content.title) {
      setSearchSuggestion({
        placeholder: PAGE_TOPIC_SUGGESTION,
        text: PAGE_TOPIC_SUGGESTION,
      });

      return;
    }
    if (currentTemplate?.content.title) {
      setSearchSuggestion({
        placeholder: PAGE_TOPIC_SUGGESTION,
        text: PAGE_TOPIC_SUGGESTION,
      });

      return;
    }
  };

  const handleChangeSearchValue = (value: string) => {
    setSearchValue(value);
    setSearchSuggestion(undefined);
  };

  const handleStop = useCallback(() => {
    dispatch(
      aiModel.actions.stopCompletion({
        id: currentResponse.id,
      })
    );
  }, [currentResponse?.id, dispatch]);

  const onDraftStart = (inputValue?: string) => {
    const searchString = inputValue || searchValue;

    let input = searchString;

    if (input.includes(PAGE_TOPIC_SUGGESTION)) {
      input = searchString.replace(
        PAGE_TOPIC_SUGGESTION,
        currentPage?.content.title || currentTemplate?.content.title || ''
      );
    }

    setSearchSuggestion(undefined);

    const updateWritingMode =
      checkIsWriteMode(startedWritingFrom) && !responses.length;

    if (updateWritingMode) {
      setEditorWritingMode(EditorWritingMode.Editor);
      setSelection({ from: selectionToRef.current });
    }

    dispatch(
      aiModel.actions.startCompletion({
        type: AIWritingActionType.DRAFT_WRITE,
        context: requestContext,
        input,
        cb: () => {
          if (
            updateWritingMode ||
            startedWritingFrom === AIStartedWritingFrom.SELECTION
          ) {
            setAIWritingAction(AIWritingActionType.DRAFT_WRITE);
          }
        },
      })
    );
  };

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        isLoading ? handleStop() : clickOutsideHandler();
      }
    };
    window.addEventListener('keydown', listener);
    return () => window.removeEventListener('keydown', listener);
  }, [isLoading, handleStop, clickOutsideHandler]);

  const onInsert = (replaceSelection: boolean) => {
    onInsertEditorContent(currentRequestContent, replaceSelection);

    setTimeout(() =>
      editor
        ?.chain()
        .setTextSelection({
          from: replaceSelection
            ? selectionFromRef.current
            : selectionToRef.current,
          to: editor.state.selection.to,
        })
        .run()
    );
    onCloseAI();
  };

  const handleGoUnlimited = () => {
    if (AIWritingAction) {
      setIsShowDiscardResponseModal(true);
      setActionOnConfirmDiscardResponse(
        ActionOnConfirmDiscardResponse.REDIRECT_TO_SUBSCRIPTION
      );
    } else {
      onCloseAI();
      redirectToSubscriptionPage();
    }
  };

  const handleCancelDiscardResponse = () => {
    setIsShowDiscardResponseModal(false);
    setActionOnConfirmDiscardResponse(null);
  };

  useEffect(() => {
    if (!isLoading) {
      if (
        startedWritingFrom === AIStartedWritingFrom.SELECTION ||
        AIWritingAction
      ) {
        editor
          ?.chain()
          .setTextSelection({
            from: selectionFromRef.current,
            to: selectionToRef.current,
          })
          .setHighlightSelection()
          .run();
      }
    }
  }, [
    isLoading,
    editor,
    currentResponse?.resultText,
    selectionFromRef,
    selectionToRef,
  ]);

  const updateSelectionHighlight = () => {
    editor
      ?.chain()
      .setTextSelection({
        from: 0,
        to: selectionToRef.current,
      })
      .setHighlightSelection()
      .run();
    setSelection({ from: 0, to: selectionToRef.current });
  };

  const searchFieldWrapperRef = useRef<HTMLDivElement>(null);

  const isShowCurrentResponse = !!currentRequestContent?.content?.length;
  const isEmptyContent = getIsContentEmpty(editor);

  useAIScroll(isLoading, menuRef, searchFieldWrapperRef);

  useEffect(() => {
    const handleDocumentClick = (e: MouseEvent | TouchEvent) => {
      const target = e.target as Node;

      if (refContainer.current && refContainer.current.contains(target)) {
        return;
      }

      const shouldShowDiscardResponseModal =
        editorWritingMode === EditorWritingMode.Editor
          ? isLoading
          : !!AIWritingAction || isLoading;

      if (shouldShowDiscardResponseModal) {
        setIsShowDiscardResponseModal(true);
        return;
      }

      onCloseAI();
    };

    document.addEventListener('mousedown', handleDocumentClick, true);

    return () => {
      document.removeEventListener('mousedown', handleDocumentClick, true);
    };
  }, [editorWritingMode, isLoading, AIWritingAction, onCloseAI]);

  return (
    <div ref={refContainer} className="bg-transparent z-10 relative">
      <div ref={searchFieldWrapperRef} style={{ width: `${editorWidth}px` }}>
        <SearchInputWrapper
          handleStart={onDraftStart}
          handleStop={handleStop}
          currentVariantIdx={currentActiveResponseIdx}
          variantsCount={responses.length}
          currentResponseContent={currentRequestContent}
          onGoUnlimited={handleGoUnlimited}
          selectNext={selectNextResponse}
          selectPrev={selectPrevResponse}
          value={searchValue}
          searchSuggestion={searchSuggestion}
          isShowCurrentResponse={isShowCurrentResponse}
        >
          <SearchInput
            ref={refSearchInput}
            value={searchValue}
            searchSuggestion={searchSuggestion}
            onChange={handleChangeSearchValue}
            isShowActionAfterWriting={!!AIWritingAction}
            onFocus={() => setFocusedElement('input')}
            onSubmit={onDraftStart}
            isShowCurrentResponse={isShowCurrentResponse}
          />
        </SearchInputWrapper>
      </div>
      <AIDraftsMenu
        isOpen={
          !isLoading &&
          startedWritingFrom === AIStartedWritingFrom.SCRATCH &&
          !AIWritingAction
        }
        onChange={handleSelectDraft}
        searchValue={searchValue}
        onFocus={() => setFocusedElement('menu')}
        ref={menuRef}
      />
      <WritingActions
        ref={menuRef}
        isOpen={
          !isLoading &&
          startedWritingFrom !== AIStartedWritingFrom.SCRATCH &&
          !AIWritingAction
        }
        startedWritingFrom={startedWritingFrom}
        handleSelectDraft={handleSelectDraft}
        context={requestContext}
        setAIWritingAction={setAIWritingAction}
        setEditorWritingMode={setEditorWritingMode}
        searchValue={searchValue}
        onFocus={() => setFocusedElement('menu')}
        updateSelectionHighlight={updateSelectionHighlight}
        isDraft={isEmptyContent}
      />
      {currentResponse && (
        <ActionsAfterWriting
          ref={menuRef}
          isOpen={!isLoading && !!AIWritingAction}
          startedWritingFrom={startedWritingFrom}
          AIWritingAction={AIWritingAction}
          onDone={onDone}
          currentResponse={currentResponse}
          onReplaceSelection={() => onInsert(true)}
          onInsertBelow={() => onInsert(false)}
          onDiscard={() => setIsShowDiscardResponseModal(true)}
          searchValue={searchValue}
          onFocus={() => setFocusedElement('menu')}
        />
      )}
      <DiscardResponseModal
        isOpen={isShowDiscardResponseModal}
        onClose={handleCancelDiscardResponse}
        onDiscard={onCloseAI}
      />
      <AIErrorHandler />
    </div>
  );
};
