import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { snippetsModel } from '../../../../../features/snippets';
import { SnippetsList } from './SnippetsList';
import { SortState } from './lib';
import { SnippetType } from '@distribute/shared/types';
import {
  getFilteredSnippets,
  getSortedSnippets,
} from '../../../../../pages/snippets/lib';
import { NoSnippetsFoundScreen } from './NoSnippetsFoundScreen';

type Props = {
  sortMethod: SortState;
  selectedFilter: SnippetType | undefined;
};

export const PersonalSnippets: React.FC<Props> = ({
  sortMethod,
  selectedFilter,
}) => {
  const personalSnippets = useSelector(
    snippetsModel.selectors.selectPersonalSnippets
  );

  const currentSnippetsToShow = useMemo(() => {
    const filteredSnippets = getFilteredSnippets(
      personalSnippets,
      '',
      selectedFilter
    );
    const sortedSnippets = getSortedSnippets(filteredSnippets, sortMethod);

    return sortedSnippets;
  }, [selectedFilter, sortMethod, personalSnippets]);

  return currentSnippetsToShow.length > 0 ? (
    <SnippetsList snippets={currentSnippetsToShow} />
  ) : (
    <NoSnippetsFoundScreen />
  );
};
