import { useDispatch, useSelector } from 'react-redux';
import { AnalyticsEvents } from '@distribute/shared/types';
import {
  getContentItems,
  useCollaboration,
} from '../../../entities/collaboration';
import { analytics } from '../../../entities/analytics';
import { chatModel } from '../model';
import { pagesModel } from '../../pages';
import { subscriptionModel } from '../../subscription';
import { teamsModel } from '../../teams';
import { checkAIExceed } from '../../subscription/lib/check-ai-exceed';
import { useSubscriptionLimits } from '../../subscription/hooks/useSubscriptionLimits';

export const useSend = () => {
  const dispatch = useDispatch();
  const { provider, title } = useCollaboration();

  const currentPage = useSelector(
    pagesModel.selectors.selectCurrentPageWithError
  );
  const currentTabId = useSelector(
    pagesModel.selectors.selectCurrentContentIdWithError
  );

  const { aiMonthlyResponsesCount: count } = useSelector(
    teamsModel.selectors.selectUserTeamMemberDataWithError
  );

  const { maxAIMonthlyResponsesCount } = useSubscriptionLimits();

  const send = (prompt: string) => {
    if (checkAIExceed(maxAIMonthlyResponsesCount, count)) {
      dispatch(
        subscriptionModel.actions.togglePaywall({ isShow: true, source: 'ai' })
      );
      return;
    }

    if (!provider) return;

    const tabs = getContentItems(provider);

    analytics.track(AnalyticsEvents.AGENT_MESSAGE_SENT, {
      pageId: currentPage.id,
      prompt,
    });

    dispatch(
      chatModel.actions.generateMessage({
        prompt,
        title,
        currentTabId,
        isBranded: currentPage.isBranded,
        tabs: tabs.reduce((acc, value) => ({ ...acc, [value.id]: value }), {}),
      })
    );
  };

  return { send };
};
