import { IconMap } from '../../../../../../src/shared/sprite';
import { LeftSidebarOptions } from '../../../model';

export const sidebarOptions = [
  {
    id: LeftSidebarOptions.TEMPLATES,
    icon: IconMap.LayersThree01,
    name: 'Templates',
  },
  {
    id: LeftSidebarOptions.SNIPPETS,
    icon: IconMap.Snippets,
    name: 'Snippets',
  },
  {
    id: LeftSidebarOptions.ELEMENTS,
    icon: IconMap.Grid02,
    name: 'Elements',
  },
  {
    id: LeftSidebarOptions.VIDEOS,
    icon: IconMap.Film02,
    name: 'Videos',
    disabled: true,
  },
  {
    id: LeftSidebarOptions.CALLS,
    icon: IconMap.HeadPhones02,
    name: 'Calls',
    disabled: true,
  },
  {
    id: LeftSidebarOptions.CONVERSIONS,
    icon: IconMap.Target04,
    name: 'Convert',
  },
  {
    id: LeftSidebarOptions.NARRATION,
    icon: IconMap.Recording,
    name: 'Narration',
    disabled: true,
  },
  {
    id: LeftSidebarOptions.SETTINGS,
    icon: IconMap.Settings04,
    name: 'Settings',
  },
];

export const LEFT_SIDEBAR_ID = 'left-sidebar-id';
